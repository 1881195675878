import React, { useState } from 'react';
import { Outlet, Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';


function Layout({children}){
    const { t, i18n } = useTranslation();

    return(
        <>
        <div class="mouse-follower">
        <span class="cursor-outline"></span>
        <span class="cursor-dot"></span>
    </div>
    <button class="scrollToTop d-none d-md-flex d-center rounded" aria-label="scroll Bar Button"><i class="mat-icon fs-four nb4-color ti ti-arrow-up"></i></button>

        <header class="header-section a2-bg header-menu w-100">
        <div class="container  d-center ">
           <nav class="navbar py-2 py-xl-4 rounded-3 navbar-expand-lg w-100 justify-content-between ">
                <div class="d-flex align-items-center py-3">
                    <button class="navbar-toggler ms-4" type="button" data-bs-toggle="collapse" aria-label="Navbar Toggler"
                    data-bs-target="#navbar-content" aria-expanded="true" id="nav-icon3">
                        <span></span><span></span><span></span><span></span>
                    </button>
                    <a href="/" class="navbar-brand m-0 p-0 d-flex align-items-center gap-5 gap-xl-5 me-2">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/fav.png`} class="logo small_logo d-sm-none" alt="logo"/>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} class="logo d-none d-sm-flex" alt="logo"/>
                    </a>
                </div>
                <div class="nav_alt">
                    <div class="right-area position-relative ms-0 d-center gap-1 gap-xl-4 d-lg-none">
                        <div class="single-item">
                            <Link to={`/${i18n.language}/login`} class="rotate_eff flex-nowrap py-1 px-2 px-xl-3 d-center gap-1 fw-bold nw1-color"> {t('header.Login')} <i class="ti ti-arrow-right fs-six-up"></i></Link>
                        </div>
                        <div class="single-item">
                            <Link to={`/${i18n.language}/signup`} class="cmn-btn fw-bold py-2 px-2 px-sm-3 px-lg-4 align-items-center gap-1"> {t('header.SignUp')}<i class="ti ti-arrow-right fw-semibold fs-six-up"></i></Link>
                        </div>
                    </div>
                </div>
                <div class="collapse navbar-collapse justify-content-center">
                    <ul class="navbar-nav gap-2 gap-lg-3 gap-xxl-8  align-self-center mx-auto mt-4 mt-lg-0">
                        <li class="dropdown show-dropdown"><Link to={`/${i18n.language}/home`}>
                            <button type="button" aria-label="Navbar Dropdown Button"
                                >{t('header.home')}</button></Link>
                           
                        </li>
                        <li class="dropdown show-dropdown"><Link to={`/${i18n.language}/about`}>
                            <button type="button" aria-label="Navbar Dropdown Button"
                                >{t('header.about')}</button></Link>
                           
                        </li>
                        <li class="dropdown show-dropdown"><Link to={`/${i18n.language}/contact`}>
                            <button type="button" aria-label="Navbar Dropdown Button"
                                >{t('header.contact')}</button></Link>
                            
                        </li>
                        <li class="dropdown show-dropdown"><Link to={`/${i18n.language}/requestloan`}>
                            <button type="button" aria-label="Navbar Dropdown Button"
                                >{t('header.loan')}</button></Link>
                            
                        </li>
                        
                    </ul>
                </div>
                <div class="right-area position-relative  ms-0 d-center gap-1 gap-xl-4 d-none d-lg-flex">
                    <div class="single-item">
                        <Link to={`/${i18n.language}/login`} class="rotate_eff flex-nowrap py-1 px-2 px-xl-3 d-center gap-1 fw-bold nw1-color"> {t('header.Login')} <i class="ti ti-arrow-right fs-six-up"></i></Link>
                    </div>
                    <div class="single-item">
                        <Link to={`/${i18n.language}/signup`} class="cmn-btn fw-bold py-2 px-2 px-sm-3 px-lg-4 align-items-center gap-1"> {t('header.SignUp')} <i class="ti ti-arrow-right fw-semibold fs-six-up"></i></Link>
                    </div>
                </div>
            </nav>
        </div>
    </header>
        
    <main>{children}</main>

    <footer class="footer a2-bg position-relative pt-15 pt-lg-0 z-0 ">
        <div class="animation position-absolute top-0 left-0 w-100 h-100 z-n1 d-none d-xxxl-flex">
            <img src={`${process.env.PUBLIC_URL}/assets/images/vector.png`} alt="vector" class="position-absolute jello"/>
            <img src={`${process.env.PUBLIC_URL}/assets/images/vector4.png`} alt="vector" class="position-absolute bottom-0 end-0"/>
        </div>
        <div class="container">
            <div class="start-earning nb3-bg cus-rounded-2 d-flex align-items-center p-4 p-sm-6 p-md-10 p-lg-15 p-xl-20 pe-lg-6 pe-xl-16 overflow-hidden position-relative">
                <div class="vector_effect position-absolute d-center justify-content-end end-0  d-flex gap-20">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/star2.png`} alt="vector" class="d-none d-xxl-flex push_animat"/>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/star_focus.png`} alt="vector" class="d-none d-sm-flex rotate time_dur ms-auto ms-lg-0 me-md-5"/>
                </div>
                <div class="row gy-6 w-100 text-center text-sm-start align-items-center justify-content-sm-between">
                    <div class="col-sm-8">
                        <h2 style={{color: 'white'}}> {t('Acceuil.trade11')} </h2>
                        <p class="fs-six-up fw_500 mt-5">{t('Acceuil.trade12')}</p>    
                    </div>
                    <div class="col-sm-4 text-sm-end">
                        <a href={`/${i18n.language}/signup`} class="cmn-btn secondary-alt ms-auto fs-five nb4-xxl-bg gap-2 align-items-center  py-2 px-4 py-lg-3 px-lg-5">{t('Acceuil.trade13')} <i class="ti ti-arrow-right fs-four"></i></a>
                    </div>
                    <div class="col-sm-4 text-sm-end">
                        <a href={process.env.PUBLIC_URL + "/apk/app-debug.apk"}  download class="cmn-btn secondary-alt ms-auto fs-five nb4-xxl-bg gap-2 align-items-center  py-2 px-4 py-lg-3 px-lg-5">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/google-play-badge-1-logo-png-transparent.png`} alt="vector" />
                        </a>
                    </div>
                </div>
            </div>
            
            <div class="row gy-8 gy-sm-12 gy-lg-0 pt-120 pb-120">
                <div class="col-6 col-lg-3">
                    <div class="footer__part">
                        <h4 class="mb-6 mb-lg-8">Lien rapide</h4>
                        <ul class="footer_list d-flex flex-column gap-2 gap-sm-3 gap-md-4">
                            <li>
                                <a class="n2-color d-flex align-items-center" href="/"> Marchés</a>
                            </li>
                            <li>
                                <a class="n2-color" href="/">Education</a>
                            </li>
                            <li>
                                <a class="n2-color" href="/">Support</a>
                            </li>
                            <li>
                                <a class="n2-color" href="/">Legal docs</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="footer__part">
                        <h4 class="mb-6 mb-lg-8">Entreprise</h4>
                        <ul class="footer_list d-flex flex-column gap-2 gap-sm-3 gap-md-4">
                            <li>
                                <a class="n2-color" href={`/${i18n.language}/home`}>{t('header.home')}</a>
                            </li>
                            <li>
                                <a class="n2-color" href={`/${i18n.language}/about`}>{t('header.about')}</a>
                            </li>
                            <li>
                                <a class="n2-color" href={`/${i18n.language}/requestloan`}>{t('header.loan')}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="footer__part">
                        <h4 class="mb-6 mb-lg-8">Legal</h4>
                        <ul class="footer_list d-flex flex-column gap-2 gap-sm-3 gap-md-4">
                            <li>
                                <a class="n2-color" href="/">Terms & Conditions</a>
                            </li>
                            <li>
                                <a class="n2-color" href="/">Privacy & Policy</a>
                            </li>
                            <li>
                                <a class="n2-color" href={`/${i18n.language}/contact`}>{t('header.contact')}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="footer__part">
                        <h4 class="mb-6 mb-lg-8">Contactez-nous</h4>
                        <div class="d-flex flex-column gap-2 gap-sm-3 gap-md-4">
                            <a href="https://pixner.net/cdn-cgi/l/email-protection#30595e565f705548515d405c551e535f5d"><span class="__cf_email__" data-cfemail="81e8efe7eec1e4f9e0ecf1ede4afe2eeec">support@cinatbuy.online</span></a>
                            <a href="tel:+123456789"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 border-top border-color opac-20 py-7 py-xxl-8">
                    <div class="footer__copyright d-center gap-15 flex-wrap justify-content-md-between">
                        <p class="fs-six order-2 order-md-0 text-center text-md-start">Copyright ©<span class="currentYear"></span> cinatbuy <span>|</span> Designed By <a href="" class="p1-color"> PDG-G</a> </p>
                        <ul class="social-area d-center gap-2 gap-md-3">
                            <li>
                                <a class="d-center cus-rounded-1 fs-four" href="#"><i class="ti ti-brand-facebook"></i></a>
                            </li>
                            <li>
                                <a class="d-center cus-rounded-1 fs-four" href="#"><i class="ti ti-brand-twitch"></i></a>
                            </li>
                            <li>
                                <a class="d-center cus-rounded-1 fs-four" href="#"><i class="ti ti-brand-instagram"></i></a>
                            </li>
                            <li>
                                <a class="d-center cus-rounded-1 fs-four" href="#"><i class="ti ti-brand-discord-filled"></i></a>
                            </li>
                            <li>
                                <a class="d-center cus-rounded-1 fs-four" href="#"><i class="ti ti-brand-youtube"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
        </>
    )
}

export default Layout;