// src/CreditForm.js
import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  useToast,
} from '@chakra-ui/react';

const CreditForm = () => {
  const [formData, setFormData] = useState({
    from_user_id: '',
    amount: '',
    nom: '',
    prenom: '',
    expediteur: '', // Removed default expediteur
  });
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`http://localhost:4000/credit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from_user_id: formData.from_user_id,
          amount: formData.amount,
          nom: formData.nom,
          prenom: formData.prenom,
        }),
      });
      const result = await response.json();
      if (result.success) {
        toast({
          title: 'Success',
          description: result.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Error',
          description: result.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while processing your request.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      maxW="md"
      mx="auto"
      mt={10}
      p={6}
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
      backgroundColor="white"
    >
      <Heading as="h2" size="lg" mb={6} textAlign="center">
        Credit Form
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="from_user_id" isRequired>
            <FormLabel>From User ID</FormLabel>
            <Input
              type="text"
              name="from_user_id"
              value={formData.from_user_id}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="amount" isRequired>
            <FormLabel>Amount</FormLabel>
            <Input
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="nom" isRequired>
            <FormLabel>Nom</FormLabel>
            <Input
              type="text"
              name="nom"
              value={formData.nom}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="prenom" isRequired>
            <FormLabel>Prenom</FormLabel>
            <Input
              type="text"
              name="prenom"
              value={formData.prenom}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="expediteur" isRequired>
            <FormLabel>Expediteur</FormLabel>
            <Input
              type="text"
              name="expediteur"
              value={formData.expediteur}
              onChange={handleChange}
            />
          </FormControl>
          <Button type="submit" colorScheme="teal" size="md" width="full">
            Submit
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default CreditForm;
