import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";

function About() {
  const { t, i18n } = useTranslation();

  return (
    <HelmetProvider>

    <>
     <Helmet>
      <title>A propos de cinatbuy bank</title>
      <meta name="description" content="Laissez-vous inspirer par notre collection de meubles et décors. Chaque pièce est choisie pour apporter charme et fonctionnalité à votre intérieur, créant un espace où il fait bon vivre" />
    </Helmet>
      <section class="banner-section  pt-120 pb-120">
        <div class="container mt-lg-0 pt-18 pt-xl-20">
          <div class="row">
            <div class="col-12 breadcrumb-area ">
              <h2 class="mb-4" style={{ color: "white" }}>
                About Us
              </h2>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-0">
                  <li class="breadcrumb-item">
                    <a href="index-2.html">Home</a>
                  </li>
                  <li
                    class="breadcrumb-item ms-2 ps-7 active"
                    aria-current="page"
                  >
                    <span>About Us</span>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section
        class="company-story position-relative z-0  pt-120 pb-120 "
        style={{ backgroundColor: "black" }}
      >
        <div class="animation position-absolute w-100 h-100 z-n1">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/star3.png`}
            alt="vector"
            class="position-absolute top-0 end-0 pt-10 pe-20 me-20 d-none d-xxl-flex previewSkew"
          />
        </div>
        <div class="container">
          <div class="row gy-15 gy-lg-0 justify-content-center align-items-center">
            <div class="col-sm-10 col-lg-6 col-xxl-5 order-2 order-lg-0">
              <div class="company-story__thumbs d-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/company_story.png`}
                  class="cus-rounded-1 w-100"
                  alt="Imgae"
                />
                <a
                  href="https://www.youtube.com/watch?v=BHACKCNDMW8"
                  class="popup-video btn-popup-animation position-absolute d-center rounded-circle"
                >
                  <i class="fa-solid fa-play fs-four"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-6 col-xxl-7">
              <div class="row ms-xl-3 ms-xxl-10">
                <div class="col-xxl-6">
                  <div class="company-story__part">
                    <span class="heading p1-color fs-five">
                      Our Company Story
                    </span>
                    <h3 class="mb-3 mt-5" style={{ color: "white" }}>
                      {t("About.about1")}
                    </h3>
                    <p> {t("About.about2")} </p>
                  </div>
                </div>
                <div class="col-xxl-12 mt-8 mt-md-10 mt-xxl-13">
                  <div class="company-story__part d-flex align-items-sm-center flex-column flex-sm-row">
                    <div class="btn-area mt-8 mt-sm-0 me-2 me-sm-6 me-xxl-10 order-2 order-sm-0">
                      <a
                        href="signup.html"
                        class="cmn-btn cmn-btn-circle d-center flex-column fw_500"
                      >
                        <i class="ti ti-arrow-up-right fs-three"></i>
                        Start Now
                      </a>
                    </div>
                    <div class="content">
                      <h3 class="mb-3" style={{ color: "white" }}>
                        {t("About.about3")}
                      </h3>
                      <p>{t("About.about4")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="provide-world our_mission pt-120 pb-120 position-relative z-0"
        style={{ backgroundColor: "black" }}
      >
        <div class="animation position-absolute top-0 left-0 w-100 h-100 z-n1">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/vector7.png`}
            alt="vector"
            class="position-absolute bottom-0 pt-6 pt-xl-15 d-none d-lg-flex push_animat"
          />
        </div>
        <div class="container">
          <div class="row justify-content-between align-items-center mb-10 mb-lg-15">
            <div class="col-xl-5">
              <span class="heading s1-color fs-five mb-5">Our Mission</span>
              <h3 style={{ color: "white" }}>
                Empowering Success How We're Making a Difference
              </h3>
            </div>
            <div class="col-xl-4">
              <p class="fs-six-up mx-ch text-xl-end mt-3 mt-xl-0">
                we believe that success is not reserved for the privileged few.
                It's a journey that anyone can embark upon with right guidance
              </p>
            </div>
          </div>
          <div class="row gy-6 gy-xxl-0">
            <div class="col-md-6 col-xxl-4">
              <div class="provide-world__card nb3-bg text-center cus-rounded-1 py-5 py-lg-10 px-4 px-lg-9">
                <span class="provide-card__icon d-center nb4-bg p-4 rounded-circle mx-auto">
                  <i class="ti ti-currency-dollar-brunei  fs-three p1-color"></i>
                </span>
                <h4 class="mt-5 mb-5" style={{ color: "white" }}>
                  {" "}
                  {t("About.about5")}{" "}
                </h4>
                <p> {t("About.about6")} </p>
              </div>
            </div>
            <div class="col-md-6 col-xxl-4">
              <div class="provide-world__card nb3-bg text-center cus-rounded-1 py-5 py-lg-10 px-4 px-lg-9">
                <span class="provide-card__icon d-center nb4-bg p-4 rounded-circle mx-auto">
                  <i class="ti ti-brand-cakephp fs-three p1-color"></i>
                </span>
                <h4 class="mt-5 mb-5" style={{ color: "white" }}>
                  {t('About.about7')}
                </h4>
                <p>
                {t('About.about8')}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-xxl-4">
              <div class="provide-world__card nb3-bg text-center cus-rounded-1 py-5 py-lg-10 px-4 px-lg-9">
                <span class="provide-card__icon d-center nb4-bg p-4 rounded-circle mx-auto">
                  <i class="ti ti-broadcast fs-three p1-color"></i>
                </span>
                <h4 class="mt-5 mb-5" style={{ color: "white" }}>
                {t('About.about9')}
                </h4>
                <p>
                {t('About.about10')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="team pb-120 pt-120 pt-xxl-0 a2-bg position-relative z-0">
        <div class="animation position-absolute top-0 left-0 w-100 h-100 z-n1">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/vector.png`}
            alt="vector"
            class="position-absolute jello d-none d-lg-flex top-0 pt-10 pt-xxl-0 "
          />
        </div>
        <div class="container">
          <div class="row">
            <div class="heading__content text-center mb-10 mb-lg-15 ">
              <span class="heading p1-color fs-five mb-5">Team</span>
              <h3 style={{ color: "white" }}> {t('About.about11')} </h3>
              <p class="mt-5 mt-xxl-6 mx-ch mx-auto">
                Trading has always been a passion, but it wasn't until I refined
                my strategy and embraced risk management.
              </p>
            </div>
          </div>
          <div class="row gy-6">
            <div class="col-sm-6 col-lg-4 col-xxl-3">
              <div class="team__card nb3-bg cus-rounded-1 overflow-hidden">
                <div class="team__thumbs position-relative">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/team.png`}
                    alt="Image"
                    class="w-100"
                  />
                </div>
                <div class="team__content pseudo_element_after transition text-center py-6 py-lg-7 py-xxl-8 px-4 px-lg-5 px-xxl-6">
                  <a href="team-details.html">
                    <h5 class="team__title d-center pb-4 mb-4 pseudo_element_after">
                      Wade Warren
                    </h5>
                  </a>
                  <p class="mb-3">
                    When I first started trading But with time and experience.
                  </p>
                  <div class="social-area alt">
                    <ul class="d-flex align-items-center justify-content-center gap-2 gap-md-3">
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-twitch"></i>
                        </a>
                      </li>
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-discord-filled"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xxl-3">
              <div class="team__card nb3-bg cus-rounded-1 overflow-hidden">
                <div class="team__thumbs position-relative">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/team2.png`}
                    alt="Image"
                    class="w-100"
                  />
                </div>
                <div class="team__content pseudo_element_after transition text-center py-6 py-lg-7 py-xxl-8 px-4 px-lg-5 px-xxl-6">
                  <a href="team-details.html">
                    <h5 class="team__title d-center pb-4 mb-4 pseudo_element_after">
                      Alice Stone
                    </h5>
                  </a>
                  <p class="mb-3">
                    When I first started trading But with time and experience.
                  </p>
                  <div class="social-area alt">
                    <ul class="d-flex align-items-center justify-content-center gap-2 gap-md-3">
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-twitch"></i>
                        </a>
                      </li>
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-discord-filled"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xxl-3">
              <div class="team__card nb3-bg cus-rounded-1 overflow-hidden">
                <div class="team__thumbs position-relative">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/team3.png`}
                    alt="Image"
                    class="w-100"
                  />
                </div>
                <div class="team__content pseudo_element_after transition text-center py-6 py-lg-7 py-xxl-8 px-4 px-lg-5 px-xxl-6">
                  <a href="team-details.html">
                    <h5 class="team__title d-center pb-4 mb-4 pseudo_element_after">
                      Jessica Jones
                    </h5>
                  </a>
                  <p class="mb-3">
                    When I first started trading But with time and experience.
                  </p>
                  <div class="social-area alt">
                    <ul class="d-flex align-items-center justify-content-center gap-2 gap-md-3">
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-twitch"></i>
                        </a>
                      </li>
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-discord-filled"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xxl-3">
              <div class="team__card nb3-bg cus-rounded-1 overflow-hidden">
                <div class="team__thumbs position-relative">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/team4.png`}
                    alt="Image"
                    class="w-100"
                  />
                </div>
                <div class="team__content pseudo_element_after transition text-center py-6 py-lg-7 py-xxl-8 px-4 px-lg-5 px-xxl-6">
                  <a href="team-details.html">
                    <h5 class="team__title d-center pb-4 mb-4 pseudo_element_after">
                      Chris Moore
                    </h5>
                  </a>
                  <p class="mb-3">
                    When I first started trading But with time and experience.
                  </p>
                  <div class="social-area alt">
                    <ul class="d-flex align-items-center justify-content-center gap-2 gap-md-3">
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-twitch"></i>
                        </a>
                      </li>
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a class="d-center fs-four" href="#">
                          <i class="ti ti-brand-discord-filled"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-10 mt-lg-15  d-flex justify-content-center">
              <a
                href="team.html"
                class="cmn-btn py-2 py-lg-3 px-4 px-lg-6 gap-2 gap-lg-3 align-items-center"
              >
                {" "}
                View More
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
    </HelmetProvider>
  );
}

export default About;
